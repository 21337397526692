import React, {useState, useContext } from "react";
import {useNavigate} from "react-router-dom";
import Alert from "../../components/alert";
import { Button } from "../../components/button";

import { setAuthorizationToken } from "../../utils";

import { UserContext } from "../../context/UserContextManagement";

import {loginUser} from "../../services/api";



const LoginForm = () => {
  const initialState = {
    username: "",
    password: "",
    errorMessage: "",
    successMessage: "",
  };

  const [state, setState] = useState(initialState);

  const {userState, setUserState} = useContext(UserContext);
  const navigate = useNavigate();
  const resetState = () => {
    setState(initialState);
  };

  const handleUsernameChange = (e) => {
    setState({ ...state, username: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setState({ ...state, password: e.target.value });
  };

  const handleLoggingIn = (e) => {
    e.preventDefault();
    const { password } = state;
    const { username } = state;
    
    loginUser(username, password)
      .then((response) => {
        const { access_token, username, is_admin } = response.data;

        localStorage.setItem("access_token", access_token);
        localStorage.setItem("user", JSON.stringify(userState));
        
        setAuthorizationToken(access_token);

        setUserState({ ...userState, username, is_admin, is_logged_in: true });
        resetState();
        navigate("/admin/radiorecording");
      })
      .catch((error) => {
        console.log("error loginForm", error);
        setState({
          ...state,
          successMessage: "",
          errorMessage: error.response.data.message,
        });

      });   
  };

  const handleAlertDismiss = (e) => {
    e.preventDefault();
    setState({ ...state, successMessage: "", errorMessage: "" });
  };

  const { successMessage, errorMessage } = state;
  const { is_logged_in } = userState;

  return (
    <form className="col-4" name="login">
      {errorMessage ? (
        <Alert
          type="danger"
          message={errorMessage}
          onClose={(e) => handleAlertDismiss(e)}
        />
      ) : null}
      {successMessage ? (
        <Alert
          type="success"
          message={successMessage}
          onClose={(e) => handleAlertDismiss(e)}
        />
      ) : null}
      <h1 className="h3 mb-3 font-weight-normal">Sign in</h1>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          id="username"
          placeholder="Username"
          autoFocus={true}
          required={true}
          onChange={(e) => handleUsernameChange(e)}
        />
      </div>
      <div className="form-group">
        <input
          type="password"
          className="form-control"
          id="password"
          placeholder="Password"
          required={true}
          onChange={(e) => handlePasswordChange(e)}
        />
      </div>
      <div className="form-group">
        <Button
          size="lg"
          type="primary"
          disabled={is_logged_in ? true : false}
          onClick={(e) => handleLoggingIn(e)}
          isSubmitting={is_logged_in}
          text="Login"
        />
      </div>
    </form>
  );
};

export default LoginForm;
