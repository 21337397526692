import React, { useEffect, useState } from "react";
import Title from "./Title";
import { Box, Button, Checkbox, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { getAuthenticatedUrl, getRecordings, getUnRecordedSchedule } from "../services/api";
import Pagination from "./Pagination";
import { Popup } from "./Popup";
import ScheduleRecording from "./dialogs/dialog_scheduleRecording";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import LiveListening from "./dialogs/dialog_liveListening";

export default function RadioRecordingComp() {

  const [state, setState] = useState({
    data: [],
    radioStations: [],
    loading: false
  });
  const [inputText, setInputText] = useState("")
  const [slicedData, setSlicedData] = useState([])
  const [openPopup, setOpenPopup] = useState(false)
  const [openPopup2, setOpenPopup2] = useState(false)
  const [search, setSearch] = useState([])
  const [searchTag, setSearchTag] = useState([])
  const [selection, setSelection] = useState('scheduled')
  const [srcUrl, setSrcUrl] = useState('')
  const [playingState, setPlayingState] = useState(false)
  const [newSchedule, setNewSchedule] = useState(false)

  const handleChange = (event, newSelection) => {
    setSelection(newSelection)
  }


  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase()
    setInputText(lowerCase)
  }

  const filteredData = state.data?.filter((el) => {
    const matchesSearch = search.length === 0 || search.some((s) => s.id === el.id)
    const matchesSearchTag = searchTag.length === 0 || searchTag.some((s) => s.radio_station === el.radio_station)
    const matchesText = inputText === '' || el.radio_show.toLowerCase().includes(inputText) || el.radio_station.toLowerCase().includes(inputText)
    return matchesSearchTag && matchesSearch && matchesText
  })

  let count = filteredData.length
  const searchId = search.map((search) => {
    return search.id
  })



  useEffect(() => {
    setState({ ...state, loading: true })
    setSrcUrl('')
    if (selection == 'scheduled') {
      getUnRecordedSchedule()
        .then((res) => {
          setState({
            ...state,
            data: res.data.schedule,
            loading: false
          });
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      getRecordings()
        .then((res) => {
          setState({
            ...state,
            data: res.data.schedule,
            loading: false
          });
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [selection, newSchedule])

  const handleUrl = (id) => {
    getAuthenticatedUrl(id)
    .then((res) => {
      setSrcUrl(res.data.authenticated_audio_url)
      setPlayingState(true)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  return (
    <>
      <Title>Radio Recording</Title>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={'end'}>
        <Box width={'20%'} display={"flex"} alignItems={"center"} >
          <TextField
            id="standard-basic"
            label="Search radio station/show"
            variant="standard"
            sx={{ width: '100%', my: 3, mr: 5 }}
            onChange={inputHandler}
          />
        </Box>
        <Box
          display={"flex"} justifyContent={"end"} alignItems={'center'}
          sx={{ width: '60%', my: 3 }}
        >
          {
            selection === 'recorded' && srcUrl != '' &&
            <audio id="player" controls src={srcUrl} autoPlay />
          }
          <ToggleButtonGroup
            color="primary"
            value={selection}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
            sx={{ width: '20%', textTransform: 'capitalize', ml: 5 }}
          >
            <ToggleButton sx={{ textTransform: 'capitalize' }} value="scheduled">Scheduled</ToggleButton>
            <ToggleButton sx={{ textTransform: 'capitalize' }} value="recorded">Recorded</ToggleButton>
          </ToggleButtonGroup>
          <Button
            variant="contained"
            sx={{ width: '25%', textTransform: 'capitalize', ml: 5, py: 1 }}
            onClick={() => setOpenPopup(true)}
          >
            Schedule recording
          </Button>
          {/* <Button
            variant="contained"
            color="error"
            sx={{ width: '10%', textTransform: 'capitalize', ml: 2, py: 1 }}
            onClick={() => setOpenPopup2(true)}
          >
            Live
          </Button> */}
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Recording Id</b>
              </TableCell>
              <TableCell align="left">
                <b>Radio Station</b>
              </TableCell>
              <TableCell align="left">
                <b>Radio Show</b>
              </TableCell>
              <TableCell align="left">
                <b>Date</b>
              </TableCell>
              <TableCell align="left">
                <b>Time Start</b>
              </TableCell>
              <TableCell align="left">
                <b>Time End</b>
              </TableCell>
              {selection === 'recorded' &&
                <TableCell align="center">
                  <b>Option</b>
                </TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedData.filter((row) =>
              searchId == '' ? row : searchId.find((id) => id == row.id)
            ).map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 },  backgroundColor: srcUrl == row.authenticated_audio_url && '#e8e8e8' }}
              >
                <TableCell align="center">{row.id}</TableCell>
                <TableCell align="left">{row.radio_station}</TableCell>
                <TableCell align="left">{row.radio_show}</TableCell>
                <TableCell align="left">
                  {(new Date(row.start_time).getDate()).toString().padStart(2, '0')}
                  {'/'}
                  {(new Date(row.start_time).getMonth() + 1).toString().padStart(2, '0')}
                  {'/'}
                  {new Date(row.start_time).getFullYear()}
                </TableCell>
                <TableCell align="left">
                  {(new Date(row.start_time).getHours()).toString().padStart(2, '0')}
                  {':'}
                  {(new Date(row.start_time).getMinutes()).toString().padStart(2, '0')}
                </TableCell>
                <TableCell align="left">
                  {(new Date(row.end_time).getHours()).toString().padStart(2, '0')}
                  {':'}
                  {(new Date(row.end_time).getMinutes()).toString().padStart(2, '0')}
                </TableCell>
                {selection === 'recorded' &&
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      sx={{ textTransform: 'capitalize' }}
                      onClick={() => {
                        handleUrl(row.id)
                      }}
                    >
                      Play
                    </Button>
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={count} filteredData={filteredData} setSlicedData={setSlicedData} data={state.data} inputText={inputText} search={search} searchTag={searchTag} />
      <Box sx={{ width: "100%" }}>
        {state.loading ? <LinearProgress /> : null}
      </Box>
      <Popup
        title="Schedule Recording"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        maxWidth="sm"
      >
        <ScheduleRecording setOpenPopup={setOpenPopup} newSchedule={newSchedule} setNewSchedule={setNewSchedule} />
      </Popup>
      <Popup
        title="Live Listening"
        openPopup={openPopup2}
        setOpenPopup={setOpenPopup2}
        maxWidth="sm"
      >
        <LiveListening />
      </Popup>
    </>
  )
}