// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-border.btn-loader--size-lg {
  width: 1.5rem;
  height: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/css/buttons.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;AAChB","sourcesContent":[".spinner-border.btn-loader--size-lg {\n  width: 1.5rem;\n  height: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
