import React, { useEffect, useRef, useState } from "react"
import Box from "@mui/material/Box";
import { Button, Grid } from "@mui/material"
import Hls from 'hls.js'


export default function LiveListening() {

    const [src, setSrc] = useState('')
    const [HLS, setHLS] = useState('')
    const audioRef = useRef(null)
    console.log(audioRef)

    const radioStations = [
        { station: 'Radio One', link: process.env.REACT_APP_RADIO_ONE },
        { station: 'Radio Plus', link: process.env.REACT_APP_RADIO_PLUS },
        { station: 'Radio Moris', link: process.env.REACT_APP_RADIO_MORIS },
        { station: 'Kool FM', link: process.env.REACT_APP_KOOL_FM },
        { station: 'Top FM', link: process.env.REACT_APP_TOP_FM },
        { station: 'Ukhozi FM', link: process.env.REACT_APP_UKHOZI_FM },
        { station: 'RSG', link: process.env.REACT_APP_RSG },
        { station: 'Lesedi FM', link: process.env.REACT_APP_LESEDI_FM },
        { station: 'Gagasi FM', link: process.env.REACT_APP_GAGASI_FM }
    ]


    useEffect(() => {
        let hls
        const audio = audioRef.current

        if (Hls.isSupported()) {
            hls = new Hls()
            hls.loadSource(HLS)
            hls.attachMedia(audio)
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                audio.play()
            })
        } else if (audio.canPlayType('application/vnd.apple.mpegurl')) {
            audio.src = HLS
            audio.addEventListener('loadedmetadata', () => {
                audio.play()
            })
        }

        return () => {
            if (hls) {
                hls.destroy()
            }
        }
    }, [HLS])


    return (
        <>
            <Box
                sx={{ maxWidth: 500 }}
                m={1} //margin
            >
                {
                    HLS ?
                    <audio controls ref={audioRef} autoPlay />
                    :
                    <audio controls src={src} autoPlay />
                }
                {radioStations.map((radioStation) => (
                    <Grid container spacing={4} marginBottom={2}>
                        <Grid item xs={6} align="left">{radioStation.station}: </Grid>
                        <Grid item xs={6} align="left">
                            <Button
                                variant="contained"
                                sx={{ width: '100%', textTransform: 'capitalize', py: 1 }}
                                onClick={() => {
                                    if(radioStation.station == 'Kool FM' || radioStation.station == 'Gagasi FM') {
                                        setHLS(radioStation.link)
                                        setSrc('')
                                    } else {
                                        setHLS('')
                                        setSrc(radioStation.link)
                                    }
                                }}
                            >
                                Play
                            </Button>
                        </Grid>
                    </Grid>
                ))}
            </Box>
        </>
    )
}