import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TablePagination from '@mui/material/TablePagination'

function Pagination(props) {

    const { count, filteredData, setSlicedData, data, inputText, search, searchTag } = props

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    let from = rowsPerPage * page
    let to = rowsPerPage * (page + 1)
    filteredData.sort((a, b) => {
        return a.radio_station.localeCompare(b.radio_station)
    })

    useEffect(() => {
        setSlicedData(filteredData.slice(from, to))
    }, [page, data, rowsPerPage, inputText, search, searchTag])

    useEffect(() => {
        setPage(0)
    }, [count])


    return (
        <Box pt={3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ '& .MuiTablePagination-selectLabel': { margin: 0 }, '& .MuiTablePagination-displayedRows': { margin: 0 } }}
            />
        </Box>
    )
}

export default Pagination