import axios from "axios";

const setAuthorizationToken = (token) => {
  console.log("setting authorization header")
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export { setAuthorizationToken };
