import React from "react";

import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const Popup = (props) =>{
    
    const {title, children, openPopup, setOpenPopup, maxWidth} = props;

    return(
        <Dialog 
        open = {openPopup}
        maxWidth={maxWidth}
        >
            <DialogTitle>
                <Typography>
                    {title}
                </Typography>
                <IconButton
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                onClick = {()=>{setOpenPopup(false)}}
                  >
                    <CloseIcon />
                </IconButton>   

            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )

}