import React, { useState } from "react";

export const UserContext = React.createContext({
  language: "en",
  username: "",
  is_logged_in: false,
  is_admin: false,
  updateUser: () => {},
});



export const UserContextProvider = (props) => {

  const initState = {
    language: "en",
    username: "",
    is_logged_in: false,
    is_admin: false,
    updateUser: (userData) => {
      console.log("updating user state", userData);
      console.log("with", { ...userState, ...userData })
      setUserState({ ...userState, ...userData });
      console.log("new state", userState)
      return userState;
    },
    setIsLoggedIn: (is_logged_in) => {
      setUserState({ ...userState, is_logged_in });
    }
  };

  const [userState, setUserState] = useState(initState);

  return (
    <UserContext.Provider value={{userState, setUserState}}>{props.children}</UserContext.Provider>
  );
};
