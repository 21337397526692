import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { createSchedule, recycleData } from "../../services/api";
import LinearProgress from "@mui/material/LinearProgress";
import { Alert, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Slide, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from "@mui/material";
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from "@mui/x-date-pickers";
import { dateFormatter, generateDates, generateTimeIntervals, timeFormatter } from "../../utils/time_date";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


export default function ScheduleRecording(props) {
    const { setOpenPopup, setNewSchedule, newSchedule } = props
    const [isLoading, setIsLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [incomplete, setIncomplete] = useState(false)
    const [radioStation, setRadioStation] = useState('')
    const [radioShow, setRadioShow] = useState('')
    const [timeInterval, setTimeInterval] = useState(15)
    const [date, setDate] = useState(dayjs(new Date()))
    const [endDate, setEndDate] = useState(dayjs(new Date()))
    const [startTime, setStartTime] = useState(dayjs(new Date()))
    const [endTime, setEndTime] = useState(dayjs(new Date()))
    const [value, setValue] = useState('Single Date');

    const radioStations = [
        'Radio One', 'Radio Plus', 'Radio Moris', 'Kool FM', 'Top FM',
        'Ukhozi FM', 'RSG', 'Lesedi FM', 'Gagasi FM'
]

    const generatedDates = generateDates(date?.$d, endDate?.$d)
    // const interval = 15 * 60 * 1000 // 15 minutes in milliseconds
    const arrangedTimeIntervals = []
    const allFormattedDates = []
    const finalStartTimeArr = []
    const finalEndTimeArr = []

    const generatedTimeIntervals = generateTimeIntervals(startTime?.$d, endTime?.$d, timeInterval * 60 * 1000)

    for (let i = 0; i < generatedTimeIntervals.length - 1; i++) {
        arrangedTimeIntervals.push({
            "start": timeFormatter(generatedTimeIntervals[i]),
            "end": timeFormatter(generatedTimeIntervals[i + 1])
        })
    }

    for (let i in generatedDates) {
        const formattedDate = dateFormatter(generatedDates[i])
        allFormattedDates.push(formattedDate)
    }

    arrangedTimeIntervals.forEach(({ start, end }) => {
        allFormattedDates.forEach(date => {
            finalStartTimeArr.push(new Date(`${date} ${start}`))
            finalEndTimeArr.push(new Date(`${date} ${end}`))
        })
    })

    const handleChange = (event) => {
        setRadioStation(event.target.value)
    }

    const handleSchedule = () => {
        if (radioStation && radioShow && date && startTime && endTime && generatedTimeIntervals.length > 1) {
            setIsLoading(true)
            setIncomplete(false)
            createSchedule(radioStation, radioShow, finalStartTimeArr, finalEndTimeArr)
                .then((res) => {
                    setIsLoading(false)
                    setSuccess(true)
                    setNewSchedule(!newSchedule)
                    setTimeout(() => {
                        setSuccess(false)
                    }, 2000)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    setError(true)
                    setTimeout(() => {
                        setError(false)
                    }, 2000)
                })
        } else {
            setIncomplete(true)
        }
    }


    return (
        <>
            <Box
                sx={{ width: 500 }}
                m={1} //margin
            >
                <Grid container spacing={2}>
                    <Grid item xs={4} align="left">Radio Channel: </Grid>
                    <Grid item xs={8} align="left">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Radio Station</InputLabel>
                            <Select
                                labelId="simple-select-label"
                                id="simple-select"
                                value={radioStation}
                                label="Radio Station"
                                onChange={handleChange}
                            >
                                {
                                    radioStations.map((station) => (
                                        <MenuItem key={station} value={station}>{station}</MenuItem>
                                    ))
                                }
                                
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} align="left">Radio Show: </Grid>
                    <Grid item xs={8} align="left">
                        <TextField
                            id="outlined-basic"
                            label="Radio Show"
                            variant="outlined"
                            sx={{ width: '100%' }}
                            value={radioShow}
                            onChange={(e) => setRadioShow(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} align="left">
                        <FormControl>
                            <FormLabel id="controlled-radio-buttons-group">Date Selection</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={(e) => {
                                    setValue(e.target.value)
                                    setEndDate(dayjs(new Date()))
                                }}
                            >
                                <FormControlLabel value="Single Date" control={<Radio />} label="Single Date" />
                                <FormControlLabel value="Range Date" control={<Radio />} label="Range Date" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} align="left">{value == "Range Date" ? "Start " : ""}Date: </Grid>
                    <Grid item xs={8} align="left">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={`Recording ${value == "Range Date" ? "Start " : ""} Date`}
                                sx={{ width: '100%' }}
                                value={date}
                                onChange={(newValue) => setDate(newValue)}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {value == "Range Date" &&
                        <>
                            <Grid item xs={4} align="left">End Date: </Grid>
                            <Grid item xs={8} align="left">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Recording End Date"
                                        sx={{ width: '100%' }}
                                        value={endDate}
                                        onChange={(newValue) => setEndDate(newValue)}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </>}
                    <Grid item xs={4} align="left">Time Interval: </Grid>
                    <Grid item xs={8} align="left">
                        <FormControl fullWidth >
                            <InputLabel id="simple-select-label">Time Interval in minutes</InputLabel>
                            <Select
                                labelId="simple-select-label"
                                id="simple-select"
                                value={timeInterval}
                                label="Time interval in minutes"
                                onChange={(e) => setTimeInterval(e.target.value)}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={11}>11</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                                <MenuItem value={13}>13</MenuItem>
                                <MenuItem value={14}>14</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} align="left">Start Time: </Grid>
                    <Grid item xs={8} align="left">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Start Time"
                                sx={{ width: '100%' }}
                                value={startTime}
                                onChange={(newValue) => setStartTime(newValue)}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4} align="left">End Time: </Grid>
                    <Grid item xs={8} align="left">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="End Time"
                                sx={{ width: '100%' }}
                                value={endTime}
                                onChange={(newValue) => setEndTime(newValue)}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'floralwhite', width: '80%', mx: 'auto', mt: '1rem' }}>
                        <InfoOutlinedIcon />
                        <Typography fontSize={12} ml={1}>Time schedule is based on 15 minute intervals</Typography>
                    </Box> */}
                </Grid>
                {incomplete && <Typography color={'red'} sx={{ backgroundColor: 'floralwhite', padding: 2 }}>All fields are required</Typography>}
                <Stack display={"flex"} flexDirection={"row"} mt={3}>
                    {!isLoading ? <Button
                        variant="contained"
                        sx={{ width: '100%' }}
                        onClick={handleSchedule}
                    >
                        Schedule
                    </Button>
                        :
                        <Button
                            variant="contained"
                            sx={{ width: '100%' }}
                            disabled
                        >
                            Schedule
                        </Button>}
                </Stack>
            </Box>
            {isLoading ? <LinearProgress /> : null}
            <Slide direction="down" in={success} mountOnEnter unmountOnExit >
                <Alert sx={{ position: 'absolute', bottom: '0', right: '0', backgroundColor: '#1a5b5f' }}
                    variant="filled" >
                    Successfully added schedule!
                </Alert>
            </Slide>
            <Slide direction="down" in={error} mountOnEnter unmountOnExit >
                <Alert sx={{ position: 'absolute', bottom: '0', right: '0' }}
                    color="error"
                    variant="filled" >
                    Error adding schedule!
                </Alert>
            </Slide>
        </>
    )
}