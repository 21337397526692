function generateTimeIntervals(startDate, endDate, interval) {
    var currentTime = new Date(startDate);
    var timeIntervals = [];

    while (currentTime <= endDate) {
        timeIntervals.push(new Date(currentTime));
        currentTime.setTime(currentTime.getTime() + interval);
    }

    return timeIntervals;
}

const generateDates = (startDate, endDate) => {
    var currentDate = new Date(startDate)
    var dates = []

    if (currentDate > endDate) {
        dates.push(new Date(currentDate))
        return dates
    }

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate))
        currentDate.setDate(currentDate.getDate() + 1)
    }

    return dates
}

const timeFormatter = (time) => {
    const timeObj = new Date(time)
    const hours = timeObj.getHours()
    const minutes = timeObj.getMinutes()
    const seconds = '00'
    const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds}`

    return formattedTime
}

const dateFormatter = (date) => {
    const dateObj = new Date(date)
    const year = dateObj.getFullYear()
    const month = dateObj.getMonth() + 1
    const day = dateObj.getDate()
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`

    return formattedDate
}

export { generateTimeIntervals, generateDates, timeFormatter, dateFormatter }