// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
#root > div {
  height: 100%;
  margin: 0;
}

#waveform {
  width: 100%;
  height: 130px;
}

#timeline {
  width: 100%;
}

textarea {
  resize: both;
}

#dictionaryTable{ 
  overflow: scroll;
  width: auto;
  margin-bottom: 1rem;
  color: #212529;
  padding: 0 10px 10px 10px;
  max-height: 50vh;

}

.red{
  background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/css/index.css"],"names":[],"mappings":"AAAA;;EAEE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;;;;EAIE,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,cAAc;EACd,yBAAyB;EACzB,gBAAgB;;AAElB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["html,\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nhtml,\nbody,\n#root,\n#root > div {\n  height: 100%;\n  margin: 0;\n}\n\n#waveform {\n  width: 100%;\n  height: 130px;\n}\n\n#timeline {\n  width: 100%;\n}\n\ntextarea {\n  resize: both;\n}\n\n#dictionaryTable{ \n  overflow: scroll;\n  width: auto;\n  margin-bottom: 1rem;\n  color: #212529;\n  padding: 0 10px 10px 10px;\n  max-height: 50vh;\n\n}\n\n.red{\n  background-color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
