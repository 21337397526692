import * as React from 'react';
import { Link } from "react-router-dom";

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AdminPanelSettings, ExpandLess, ExpandMore, Handyman, StarBorder } from '@mui/icons-material';
import { Collapse, List } from '@mui/material';
import RadioIcon from '@mui/icons-material/Radio';

export const MainListItems = () => {

  return (
    <></>
  )
};
export const SecondaryListItems = () => {
  const [open, setOpen] = React.useState(false)
  const [open1, setOpen1] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
    setOpen1(false)
  }
  const handleClick1 = () => {
    setOpen1(!open1)
    setOpen(false)
  }
  return (
    <React.Fragment>
      <Link className="nav-link" style={{ color: 'inherit', textDecoration: 'inherit' }}>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <AdminPanelSettings />
          </ListItemIcon>
          <ListItemText primary="Admin" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Link className="nav-link" to="/admin/radiorecording" style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItemButton>
              <ListItemIcon>
                <RadioIcon />
              </ListItemIcon>
              <ListItemText primary="Radio" />
            </ListItemButton>
          </Link>
        </Collapse>
      </Link>
    </React.Fragment>
  )
}

