import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./app";
import { setAuthorizationToken } from "./utils";

import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

import "./css/index.css";
import "./css/buttons.css";

import { UserContextProvider } from "./context/UserContextManagement";

const root = ReactDOM.createRoot(document.getElementById('root'));

// console.log("setting authorization header from index", localStorage.getItem("access_token"))
// setAuthorizationToken(localStorage.getItem("access_token"));

root.render(
    <BrowserRouter>
      <UserContextProvider>
        <App />
      </UserContextProvider>
    </BrowserRouter>
);
