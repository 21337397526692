import { REACT_APP_API_URL } from "../constants";
import { setAuthorizationToken } from "../utils";
import axios from "axios";

// API requests for Project Loading please refer to API documentation

const api = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: 60000,
});

api.interceptors.request.use(
  (config) => {
    // TODO: should only need access_token set from setAuthorizationToken
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = `${token}`;
    }
    return config;
  },
  (error) => {
    console.log("api error: ", error);
    const statusCode = error.response ? error.response.status : null;
    if (statusCode === 401) {
      
      localStorage.removeItem("access_token");
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);



// API requests for User Authentication

export const getUsers = async () => {
  return await api.get(`api/users`);
};

export const logoutUser = async () => {
  return await api
    .delete(`auth/logout`)
    .then(() => {
      localStorage.removeItem("access_token");
      setAuthorizationToken(null);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const checkUserLoggedIn = async () => {
  return await api.post(`auth/is_logged_in`);
};

export const loginUser = async (username, password) => {
  return await api.post(`auth/login`, { username, password });
};


// API Services for User Management
// POST Users with Username, Password and Role
export const createUser = async (username, password, role) => {
  console.log("API Log:", username, password, role);
  return await api.post(`api/users`, {
    username,
    password,
    role,
  });
};


// Get Information on one single user takes as input the userId
export const getSingleUser = async (userId) => {
  return await api.get(`api/users/${userId}`);
};

// Patch single user information
export const updateUser = async (userId, role, is_marked_revoke) => {
  return await api.patch(`api/users/${userId}`, {
    role,
    is_marked_revoke,
  });
};

// API services for Project Management
// Patch User assignation to project

export const assignUser = async (projectId, selectedUsers) => {
  return await api.patch(`api/projects/${projectId}/users`, {
    users: selectedUsers,
  });
};


export const getRecordings = async () => {
  return await api.get(`api/recording_schedule`)
}

export const getUnRecordedSchedule = async () => {
  return await api.get(`api/unrecorded_schedule`)
}

export const createSchedule = async (radio_station, radio_show, start_time, end_time) => {
  return await api.post(`api/recording_schedule`, { radio_station: radio_station, radio_show: radio_show, 
    start_time: start_time, end_time: end_time })
}

export const getAuthenticatedUrl = async (id) => {
  return await api.post(`api/authenticated_url`, {id})
}