import React, { useEffect, useContext } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";

import { Helmet } from "react-helmet";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  Home,
  RadioRecording
} from "./pages";

import NavBar from "./containers/navbar";

import { UserContext } from "./context/UserContextManagement";

import { checkUserLoggedIn } from "./services/api";



const mdTheme = createTheme({
  palette: {
    primary: {
      main: '#1a5b5f'
    }
  }
});

const App = () => {
  const { userState, setUserState } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const check_logged_in = () => {
    //TODO: ad a flag for login to skip login check on dev

    checkUserLoggedIn()
      .then((response) => {
        const { is_logged_in } = response.data;
        userState.updateUser({ is_logged_in });
        console.log("location.pathname", location.pathname)
        if (is_logged_in === true) {
          const { username, is_admin } = response.data;
          setUserState({ ...userState, is_admin, username });
          if (location.pathname === "/") {
            navigate("/admin/radiorecording");
          }
        }
        
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response.status === 401) {
          navigate("/");
          setUserState({ ...userState, is_logged_in: false });
        }
      });
  };

  useEffect(() => {
    console.log("Check if login")
    const access_token = localStorage.getItem("access_token");
    console.log("acesstoken", access_token);
    if (access_token) {

      setUserState({ ...userState, is_logged_in: true });
    } else {
      check_logged_in();
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />

          <Helmet
            titleTemplate="%s | Hydrus Labs Crowd Source"
            defaultTitle="Hydrus Labs Crowd Source"
          ></Helmet>
          <NavBar />
          <Routes>
            <Route
              path="/"
              element={userState.is_logged_in ? <Navigate to="/admin/radiorecording" /> : <Home />} />
            <Route path="/empty" key="empty" />
            <Route path="/admin/radiorecording" element={<RadioRecording />} />
            <Route path="*" element={<Error message="Page not found!" />}>
            </Route>
          </Routes>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default App;
